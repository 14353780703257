<template>
  <div id="app">
    <CriticalAlert v-if="displayCriticalAlert"></CriticalAlert>
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./components/Header";
import AppFooter from "./components/Footer";
import CriticalAlert from "./components/CriticalAlert";

export default {
  name: "CommunityPortal",
  components: {
    AppHeader,
    AppFooter,
    CriticalAlert,
  },
  computed: {
    displayCriticalAlert() {
      return !!this.$appSettings.CriticalAlert.Display;
    },
  },
};
</script>
