<template>
  <div class="simple-search" v-click-outside="onClickOut">
    <form
      ref="searchForm"
      class="simple-search__form"
      :id="`header-search-form-${_uid}`"
      :aria-hidden="formOpen ? 'false' : 'true'"
      @submit.prevent="onSubmit"
    >
      <label
        v-if="label"
        :class="{ 'sr-only': !isLabelVisible }"
        :for="`simple-search-input-${_uid}`"
      >
        {{ label }}
      </label>
      <div
        class="simple-search__input-box"
        :class="{
          'simple-search__input-box--collapsible': isCollapsible,
          active: formOpen,
        }"
      >
        <input
          type="search"
          name="keyword"
          v-model="query"
          ref="search"
          class="simple-search__input"
          :class="{
            'simple-search__input--collapsible': isCollapsible,
            active: formOpen,
          }"
          :id="`simple-search-input-${_uid}`"
          :placeholder="placeholderText"
          @input="$emit('queryUpdate', query)"
          @keypress.enter="onSubmit"
        />
        <button
          v-show="isCollapsible || query.length"
          class="simple-search__close"
          @click.prevent="onCloseClick"
          type="reset"
        >
          <icon name="close" />
        </button>
      </div>

      <button
        class="btn btn--has-icon simple-search__btn"
        :class="{
          active: formOpen,
          'simple-search__btn--collapsible': isCollapsible,
        }"
        aria-haspopup="true"
        aria-owns="header-search-form"
        type="submit"
        name="action"
        value="Search"
        @click.prevent="toggleOrSubmit"
      >
        <icon name="search" class="btn__icon btn__icon--left btn__icon--md" />
        Search
      </button>
    </form>
  </div>
</template>
<script>
import get from "lodash/get";

export default {
  name: "SimpleSearch",

  props: {
    label: {
      type: String,
      default: "Search the portal",
    },
    placeholder: {
      type: String,
    },
    isLabelVisible: {
      type: Boolean,
      default: false,
    },
    isCollapsible: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: String,
      default: "",
    },
    searchOnSubmit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      formOpen: !this.isCollapsible,
      query: this.initialValue,
      placeholderText:
        this.placeholder ||
        get(this.$appSettings, "Header.SearchPlaceholder", "") ||
        "I'm looking for...",
    };
  },

  computed: {
    searchUrl() {
      return `/search/${this.query}`;
    },
  },

  methods: {
    toggleOrSubmit(e) {
      // click gets triggered when enter is pressed and form is submitted, prevent double handling
      if (e.x === 0 && e.y === 0) {
        return;
      }

      if (this.query.length) {
        this.onSubmit();
      } else {
        if (this.isCollapsible) {
          this.formOpen ? this.closeForm() : this.openForm();
        }
      }
    },

    openForm() {
      this.formOpen = true;
      this.query = "";
      this.$emit("form:open");

      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },

    closeForm() {
      // Hide form if property is setup that form is hidden on initial
      this.formOpen = false;
      this.query = "";
      this.$emit("form:close");
    },

    onSubmit() {
      if (this.searchOnSubmit) {
        const isSearchPage = this.$route.name === "search";
        this.$router.push(this.searchUrl);

        if (isSearchPage) {
          location.reload();
        }

        this.$nextTick(() => {
          this.closeForm();
        });
      } else {
        this.$emit("submit", this.query);
      }
    },

    onClickOut() {
      if (this.isCollapsible && this.formOpen) {
        this.closeForm();
      }
    },

    onCloseClick() {
      if (this.isCollapsible) {
        this.closeForm();
      } else {
        this.query = "";
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.simple-search {
  max-width: 100%;
}

.simple-search__form {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  height: rem(45px);
  width: 100%;
  position: relative;

  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

.simple-search__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color linear $anim-hover;
  border: 2px solid var(--color-bg-header-btn);
  border-radius: 0 var(--radius-br-header-btn) var(--radius-br-header-btn) 0;
  background-color: var(--color-bg-header-btn);
  color: var(--color-fg-header-btn);
  font-size: var(--font-size-header-link);
  font-weight: var(--font-weight-header-link, 500);
  @include media-breakpoint-up(lg) {
    border-radius: var(
      --radius-br-desktop-search-header-btn,
      var(--radius-br-header-btn)
    );

    margin-left: rem(8px);

    &--collapsible {
      margin-left: 0;
      &.active {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &:active,
  &:hover {
    background-color: var(
      --color-bg-hover-header-btn,
      var(--color-bg-hover-btn)
    );
  }
}

.simple-search__input-box {
  position: relative;
  flex: 1;

  &--collapsible {
    @include media-breakpoint-up(lg) {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.simple-search__close {
  @include btn-reset();

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: rem(8px);
  color: $color-black-80;
  line-height: 0;
}

.simple-search__input {
  color: var(--color-fg-search-input-header);
  font-size: rem(16px);
  font-weight: 300;
  letter-spacing: rem(0.24px);
  line-height: rem(28px);
  border: 1px solid var(--color-br-search-input-header);
  border-radius: var(--radius-br-header-btn) 0 0 var(--radius-br-header-btn);
  background-color: #ffffff;
  height: rem(45px);
  padding: rem(9px) rem(32px) rem(9px) rem(9px);
  appearance: none;
  min-width: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    border-radius: var(--radius-br-header-btn);
  }

  &--collapsible {
    width: rem(336px);
    position: absolute;
    right: 100%;

    @include media-breakpoint-up(lg) {
      &.active {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }

  &::placeholder {
    color: var(--color-fg-search-placeholder-header);
    font-style: normal;
    opacity: 1;
  }
  &::placeholder {
    color: var(--color-fg-search-placeholder-header);
    font-style: normal;
    opacity: 1;
  }
}
</style>
