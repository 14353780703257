var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('nav',{staticClass:"main-nav",attrs:{"role":"presentation","aria-label":"Main Navigation"}},[_c('button',{staticClass:"main-nav__reveal",attrs:{"id":"open-menu","aria-owns":"mobile-menu","aria-haspopup":"true"},on:{"click":function($event){return _vm.toggleMenu()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleMenu()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Toggle the mobile menu")]),_c('icon',{staticClass:"main-nav__reveal-icon",attrs:{"name":_vm.menuTriggerIcon}}),_c('span',{staticClass:"main-nav__reveal-text",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.menuTriggerText))])],1),_c('div',{class:{ 'main-nav__list-wrap': true, open: _vm.mobileMenuOpen },attrs:{"id":"mobile-menu","data-hidden":"true"}},[_c('ul',{staticClass:"main-nav__list"},[_c('li',{class:{
          'main-nav__item': true,
          'main-nav__item--search': true,
          'main-nav__item--mobile': true,
        },attrs:{"tabindex":"-1"}},[_c('SimpleSearch',{staticClass:"main-nav__search main-nav__search--mobile"})],1),_vm._l((_vm.items),function(item){return _c('li',{key:item.ID,class:{
          'main-nav__item': true,
          'main-nav__item--has-submenu': item.Children.length,
          'main-nav__item--no-submenu': !item.Children.length,
          'open current':
            _vm.itemOpen === item.ID || _vm.$route.path.startsWith(item.Url),
          hidden: _vm.hideMenuItems,
        },attrs:{"tabindex":"-1"},on:{"mouseover":function($event){return _vm.openMenu(item)},"mouseleave":function($event){return _vm.closeMenu(item)}}},[_c('div',{staticClass:"main-nav__link-wrap"},[_c('a',{class:{
              'main-nav__link': true,
              current: _vm.$route.path.startsWith(item.Url),
              open: _vm.itemOpen === item.ID,
            },attrs:{"href":item.Url,"aria-expanded":_vm.itemOpen === item.ID ? 'true' : 'false',"aria-haspopup":item.Children.length ? 'true' : 'false'},on:{"click":function($event){$event.preventDefault();return _vm.clicked(item)}}},[_vm._v(_vm._s(item.Title))]),(item.Children.length)?_c('button',{class:{ 'main-nav__submenu-trigger': true },attrs:{"aria-label":'Open ' + item.Title + ' sub menu',"aria-expanded":_vm.itemOpen === item.ID ? 'true' : 'false',"aria-controls":'nav-children-' + item.ID},on:{"click":function($event){$event.preventDefault();return _vm.toggleMobileSubMenu(item)}}},[_c('icon',{class:{ 'main-nav__icon': true, open: _vm.itemOpen === item.ID },attrs:{"name":_vm.menuItemIcon(item)}})],1):_vm._e()]),_c('hr',{class:{ 'main-nav__item-sep': true, open: _vm.itemOpen === item.ID }}),(item.Children.length)?_c('ul',{class:{
            'main-nav__submenu': true,
            open: _vm.itemOpen === item.ID,
          },attrs:{"aria-labelledby":'nav-item-btn-' + item.ID,"aria-hidden":_vm.itemOpen === item.ID ? 'true' : 'false',"id":'nav-children-' + item.ID}},_vm._l((item.Children),function(child){return _c('li',{key:'child' + child.ID,staticClass:"main-nav__item main-nav__item--submenu",on:{"click":function($event){return _vm.clicked(child)}}},[_c('a',{class:{
                'main-nav__link': true,
                'main-nav__link--submenu': true,
              },attrs:{"href":child.Url,"data-nav-item":"nav-children-{$ID}"}},[_vm._v(_vm._s(child.Title))])])}),0):_vm._e()])}),_c('li',{class:{
          'main-nav__item': true,
          'main-nav__item--search': true,
          'main-nav__item--desktop': true,
        },attrs:{"tabindex":"-1"}},[_c('SimpleSearch',{staticClass:"main-nav__search main-nav__search--desktop",attrs:{"is-collapsible":true},on:{"form:open":function($event){_vm.hideMenuItems = true},"form:close":function($event){_vm.hideMenuItems = false}}})],1)],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }