<template>
  <div id="site-main" class="site-main" role="main">
    <div class="page search-results-page">
      <MultilineHero title="Your Search" class="hide-print">
        <SimpleSearch
          :initialValue="keyword"
          :searchOnSubmit="false"
          @queryUpdate="onKeywordUpdate"
          @submit="onKeywordUpdate"
        />
      </MultilineHero>

      <SearchFilters
        v-model="filters"
        :filters="$appSettings.Filters"
        :multiple="false"
      />

      <div class="page__inner page__inner--center container pt-0">
        <Loader v-if="isLoading" />
        <div v-else v-show="showResults" class="search-content">
          <SearchSummary
            :hide-view-switcher="hideViewSwitcher"
            :view="view"
            :total="currentResults.length"
            :print-list="printList"
            @changeView="view = $event"
            @toggleShowPrint="showPrint = !showPrint"
          />

          <SearchResults
            v-if="isListView"
            :results="currentItems"
            :print-list="printList"
            :show-print="showPrint"
            @addToPrint="addToPrint"
          />
          <SearchLocations v-else :results="mapResults" />

          <Pagination
            v-show="isListView"
            class="page__block"
            v-model="paginationParams"
            :itemsCount="results.length"
            @pageChange="updateURL(true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import MultilineHero from "@/components/MultilineHero";
import SimpleSearch from "@/components/SimpleSearch";
import Loader from "@/components/Loader";
import SearchFilters from "@/components/SearchFilters";
import SearchLocations from "@/components/SearchLocations";
import SearchResults from "@/components/SearchResults";
import Pagination from "@/components/Pagination";
import SearchSummary from "@/components/SearchSummary";

const QUERY_PARAMS = [
  "Category",
  "ExcludeNationwide",
  "ContentType",
  "CoverageArea",
];

import { TYPE_MAP, TYPE_LIST } from "@/components/SearchViewSwitcher";

export default {
  name: "SearchResult",
  components: {
    MultilineHero,
    SimpleSearch,
    Loader,
    SearchFilters,
    SearchResults,
    Pagination,
    SearchLocations,
    SearchSummary,
  },

  data() {
    return {
      isLoading: true,
      isInitialLoad: true,
      keyword: "",
      results: [],
      paginationParams: { page: 1, limit: 10, offset: 0 },
      filters: {
        Category: "",
        ExcludeNationwide: false,
        ContentType: "",
        CoverageArea: "",
      },
      view: TYPE_LIST,
      printList: [],
      showPrint: false,
    };
  },

  computed: {
    showResults() {
      return !!this.searchQuery.length;
    },

    mapResults() {
      return this.results.filter(
        (item) => item.type === "ServiceProvider" && item.location
      );
    },

    currentResults() {
      return this.view === TYPE_MAP ? this.mapResults : this.results;
    },

    hideViewSwitcher() {
      return !this.mapResults.length;
    },

    currentItems() {
      return this.paginationParams.page
        ? this.results.slice(
            this.paginationParams.offset,
            this.paginationParams.limit
          )
        : [];
    },

    isListView() {
      return this.view === TYPE_LIST;
    },

    /**
     * Get an object containing all of the parameters to be send as part of the AJAX search request.
     *
     * @returns {Object}
     */
    hashFilters() {
      const filters = { ...this.filters };
      // Category(Tags) are part of the query to align with the main site search behavior
      delete filters.Category;
      // Remove filters that are used for community portal url query
      delete filters.CoverageArea;
      delete filters.ContentType;

      return filters;
    },

    /**
     * Get query string for AJAX search request.
     *
     * @returns {string}
     */
    searchHash() {
      return new URLSearchParams({
        ...this.hashFilters,
      }).toString();
    },

    searchQuery() {
      let keyword = "";

      if (this.keyword.length) {
        keyword = this.keyword;
      }

      let categoryStr = "";

      if (this.filters.Category && this.filters.Category.length) {
        categoryStr = `${JSON.stringify(this.filters.Category)}`;
      }

      return `${keyword}tag:${categoryStr}`;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        // Force search results update when filters changes. This also happens on page load
        this.debouncedUpdateResults(true);
      },
    },
    $route(newRoute, oldRoute) {
      if (newRoute.query.page !== oldRoute.query.page) {
        this.paginationParams.page = newRoute.query.page
          ? parseInt(newRoute.query.page)
          : 1;
        this.paginationParams.offset = (this.paginationParams.page - 1) * 10;
        this.paginationParams.limit = this.paginationParams.offset + 10;
      }
    },
  },

  created() {
    this.keyword = this.$route.params.query || "";
    this.paginationParams.page = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : 1;
  },

  methods: {
    debouncedUpdateResults: debounce(function (forceRequest = false) {
      this.updateResults(forceRequest);
    }, 500),

    updateResults(forceRequest = false) {
      if (this.showResults) {
        this.isLoading = true;

        if (this.isInitialLoad) {
          this.isInitialLoad = false;
        }

        // Get current URL request
        const urlData = this.getUrlData();

        // Prevent sending the same request more than once if the current state URL
        // matches the URL to be sent unless the search button is clicked.
        if (
          !forceRequest &&
          window.history.state &&
          window.history.state.stateUrl !== undefined &&
          window.history.state.stateUrl === urlData.url
        ) {
          return;
        }

        this.getResults().then((resultsData) => {
          this.results = resultsData.results;
          this.isLoading = false;
          this.updateURL(true);

          if (this.hideViewSwitcher) {
            this.view = TYPE_LIST;
          }
        });
      } else {
        this.isLoading = false;
      }
    },

    getResults() {
      let params = {};

      if (this.searchQuery) {
        params.q = this.searchQuery;
      }

      if (this.searchHash) {
        params.hash = this.searchHash;
      }

      return new Promise((resolve, reject) => {
        this.$axios
          .get("/api/v1/search", {
            params: params,
          })
          .then((response) => {
            let results;
            if (
              response.status === 204 ||
              typeof response.data.results === "undefined"
            ) {
              results = [];
            } else {
              results = response.data.results;
            }

            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onKeywordUpdate(keyword) {
      this.keyword = keyword;

      // Update search results an ensure the request is going to be send
      this.debouncedUpdateResults(true);
      this.paginationParams.page = 1;
    },

    getUrlData() {
      // Create a collection of filters that are allowed to be visible in the URL
      let filters = {};

      Object.entries(this.filters).forEach(([name, value]) => {
        if (QUERY_PARAMS.includes(name)) {
          filters[name] = value;
        }
      });

      if (this.paginationParams.page) {
        filters.page = this.paginationParams.page;
      }

      // Convert the filters into URL parameters and append to the current URL
      const query = new URLSearchParams(filters).toString();

      // URL query string
      let queryString = query ? `?${query}` : "";

      // URL to return
      const url = `${window.location.origin}/search/${this.keyword}${queryString}`;

      return {
        url,
        filters,
      };
    },

    updateURL(pageChanged = false) {
      // Get object containing data about the current URL
      const urlData = this.getUrlData();

      if (pageChanged) {
        const newRoute = {
          name: "search",
          query: urlData.filters,
        };

        if (this.keyword && this.keyword !== "") {
          newRoute.params = {
            query: this.keyword,
          };
        }

        this.$router.push(newRoute);
      }
    },

    addToPrint({ event, item }) {
      event.target.checked
        ? this.printList.push(item)
        : (this.printList = this.printList.filter((v) => v.code !== item.code));
    },
  },
};
</script>

<style lang="scss">
.search-results-page .container {
  @include media-breakpoint-up(lg) {
    max-width: 936px;
  }

  .ss-multi-selected .ss-values .ss-value {
    background-color: white;
    color: #323232;
  }
}
.search-form {
  display: flex;
}
</style>
