<template>
  <div class="search-results">
    <ul
      :class="{ 'search-results__list': true, 'hide-print': printList.length }"
    >
      <li
        v-if="!results.length"
        class="search-result typography search-result--empty"
      >
        <div class="page__block">
          <h2>We're sorry, we couldn't find anything matching that search</h2>
          <p>Please try using a different search term</p>
        </div>
      </li>
      <SearchResult
        v-for="(result, i) in results"
        :key="`result-${i}`"
        :result="result"
        :show-print="showPrint"
        :is-print-selected="isPrintSelected(result)"
        @add-to-print="$emit('addToPrint', $event)"
      />
    </ul>

    <ul
      :class="{
        'search-results__list': true,
        'show-print': printList.length,
        'hide-print': !printList.length,
      }"
    >
      <SearchResult
        v-for="(result, i) in printList"
        :key="i"
        :result="result"
      />
    </ul>
  </div>
</template>

<script>
import SearchResult from "./SearchResult";

export default {
  name: "SearchResults",
  components: {
    SearchResult,
  },

  props: {
    results: Array,
    printList: Array,
    showPrint: Boolean,
  },

  computed: {},

  methods: {
    isPrintSelected(item) {
      return (
        this.printList.findIndex((result) => result.code === item.code) !== -1
      );
    },
  },
};
</script>

<style lang="scss">
.search-results__list {
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}
</style>
