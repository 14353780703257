var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOut),expression:"onClickOut"}],staticClass:"simple-search"},[_c('form',{ref:"searchForm",staticClass:"simple-search__form",attrs:{"id":("header-search-form-" + _vm._uid),"aria-hidden":_vm.formOpen ? 'false' : 'true'},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.label)?_c('label',{class:{ 'sr-only': !_vm.isLabelVisible },attrs:{"for":("simple-search-input-" + _vm._uid)}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"simple-search__input-box",class:{
        'simple-search__input-box--collapsible': _vm.isCollapsible,
        active: _vm.formOpen,
      }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"simple-search__input",class:{
          'simple-search__input--collapsible': _vm.isCollapsible,
          active: _vm.formOpen,
        },attrs:{"type":"search","name":"keyword","id":("simple-search-input-" + _vm._uid),"placeholder":_vm.placeholderText},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value},function($event){return _vm.$emit('queryUpdate', _vm.query)}],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit($event)}}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapsible || _vm.query.length),expression:"isCollapsible || query.length"}],staticClass:"simple-search__close",attrs:{"type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.onCloseClick($event)}}},[_c('icon',{attrs:{"name":"close"}})],1)]),_c('button',{staticClass:"btn btn--has-icon simple-search__btn",class:{
        active: _vm.formOpen,
        'simple-search__btn--collapsible': _vm.isCollapsible,
      },attrs:{"aria-haspopup":"true","aria-owns":"header-search-form","type":"submit","name":"action","value":"Search"},on:{"click":function($event){$event.preventDefault();return _vm.toggleOrSubmit($event)}}},[_c('icon',{staticClass:"btn__icon btn__icon--left btn__icon--md",attrs:{"name":"search"}}),_vm._v(" Search ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }