<template>
  <article id="site-main" class="site-main" role="main">
    <div class="page">
      <Hero :title="title"></Hero>

      <div class="container page__inner page__inner--center">
        <div class="page__content">
          <div
            class="typography page__content-inner"
            v-html="content"
            v-mobile-table
            v-alert
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import metaMixin from "@/mixins/meta";
import errorMixin from "@/mixins/error-page";
import Hero from "@/components/Hero";
import get from "lodash/get";

export default {
  name: "Page",

  components: {
    Hero,
  },

  mixins: [metaMixin, errorMixin],

  data() {
    return {
      defaultMessage:
        "<p>Please contact the helpdesk on <strong>0800 485 422</strong> if you believe this is an error.</p>",
    };
  },

  computed: {
    title() {
      if (this.errorServerError) {
        return get(this.$cmsServerError, "title", "Server error");
      }

      return get(this.$cmsNotFound, "title", "Page not found");
    },

    content() {
      if (this.errorServerError) {
        return get(this.$cmsServerError, "content", this.defaultMessage);
      }

      return get(this.$cmsNotFound, "content", this.defaultMessage);
    },

    metaTitle() {
      return this.title;
    },

    metaDescription() {
      return "";
    },
  },
};
</script>
