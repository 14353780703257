<script>
import icons from "../../icons";

export default {
  render: () => null,

  props: {
    map: {
      type: Object,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isActive() {
      this.marker.setIcon(this.markerIcon);
    },
  },

  mounted() {
    /* global google */
    this.marker = new google.maps.Marker({
      position: this.feature.location,
      map: this.map,
      icon: this.markerIcon,
    });

    const markerListeners = ["click"];

    Object.keys(this.$listeners)
      .filter((handle) => markerListeners.includes(handle))
      .forEach((handle) => {
        google.maps.event.addListener(this.marker, handle, (event) => {
          this.$emit(handle, { event, marker: this.marker });
        });
      });
  },

  computed: {
    markerIcon() {
      // Primary and secondary color marker fill color
      const color = this.$appSettings.Theme?.Colors.Primary || "#323232";
      const colorActive =
        this.$appSettings.Theme?.Colors.Secondary || "#0141CE";

      // Base configuration of marker icon
      let icon = {
        path: icons.location.d,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: "white",
        strokeWeight: 2,
        anchor: { x: 17, y: 42 },
        scale: 1,
      };

      if (this.isActive) {
        icon = {
          ...icon,
          fillColor: colorActive,
          scale: 1.3,
        };
      }

      return icon;
    },
  },
};
</script>
