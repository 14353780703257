<template>
  <div class="search-filters" v-if="filters.length">
    <div class="container search-filters__container">
      <div class="search-filters__row">
        <div
          class="search-filters__filter"
          v-for="filter in filters"
          :key="filter.FilteredProperty"
        >
          <Multiselect
            class="search-filters__multiselect"
            v-model="filtersValues[filter.FilteredProperty]"
            :options="filter.Options"
            :allow-deselect="true"
            :placeholder="filter.Placeholder"
            :searchable="filter.Searchable"
            @empty="
              filter.CheckboxField
                ? (filtersValues[filter.CheckboxField.name] = '')
                : null
            "
            :multiple="filter.FilteredProperty == 'Category'"
          />
          <div
            v-if="filter.CheckboxField"
            v-show="filtersValues[filter.FilteredProperty]"
            class="search-filters__checkbox"
          >
            <input
              type="checkbox"
              v-model="filtersValues[filter.CheckboxField.name]"
              :id="`checkbox-${filter.CheckboxField.name}`"
            />
            <label :for="`checkbox-${filter.CheckboxField.name}`">
              {{ filter.CheckboxField.text }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@/components/MultiSelect";

export default {
  name: "SearchFilters",
  components: {
    Multiselect,
  },

  props: {
    value: Object,
    filters: Array,
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filtersValues: {},
    };
  },

  computed: {
    activeFilters() {
      let filters = {};

      // filter out empty values
      Object.entries(this.filtersValues).forEach(([name, value]) => {
        // checkboxes
        if (value === true) {
          value = "1";
        }

        if (value.length) {
          filters[name] = value;
        }
      });

      // Content type filter
      // format content type to align with backend
      if (filters.ContentType) {
        filters[filters.ContentType] = 1;
      } else {
        // include all three content types by default, otherwise some
        // parameters processing in searchController would not work
        ["qa", "sp", "ir"].forEach((contentType) => {
          filters[contentType] = 1;
        });
      }

      // Categories (=Tags) filter
      if (filters.Category && !Array.isArray(filters.Category)) {
        filters.Category = [filters.Category];
      }

      // Coverage area filter
      if (filters.CoverageArea) {
        const [locality, city] = filters.CoverageArea.split("|");
        filters.locality = locality;

        if (city) {
          filters.city = city;
        }
      }

      // Exclude national listing
      if (filters.ExcludeNationwide && filters.ExcludeNationwide === "1") {
        filters.ExcludeNationwide = "1";
      }

      return filters;
    },
  },

  watch: {
    filtersValues: {
      deep: true,
      handler() {
        this.$emit("input", this.activeFilters);
      },
    },
  },

  created() {
    this.initialValues();
    this.$emit("input", this.activeFilters);
  },

  methods: {
    initialValues() {
      if (this.$route.query !== undefined) {
        // Copy values from route query
        let values = {
          ...this.$route.query,
        };
        // Convert boolean values to true/false
        Object.entries(this.$route.query).forEach(([name, value]) => {
          if (value === "1" || value === "0") {
            values[name] = value === "1";
          }
        });
        // Update filters values
        this.filtersValues = values;
      }
    },
  },
};
</script>

<style lang="scss">
.search-filters {
  padding: $base-spacing-secondary 0;
  background-color: var(--color-primary-lightest);
}

.search-filters__row {
  display: flex;
  margin-left: -1 * ($base-spacing / 2);
  margin-right: -1 * ($base-spacing / 2);
  margin-bottom: -1 * $base-spacing;
  flex-wrap: wrap;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.search-filters__filter {
  margin-bottom: $base-spacing;
  flex: 1;
  margin-left: $base-spacing / 2;
  margin-right: $base-spacing / 2;

  @include media-breakpoint-up(md) {
    max-width: rem(280px);
  }
}

.search-filters__multiselect {
}

.search-filters__checkbox {
  margin-top: $base-spacing-secondary;
}

@media print {
  .search-filters {
    display: none;
  }
}
</style>
