<template>
  <div v-if="parsedDetails.length" class="contacts">
    <div
      v-for="(contact, i) in parsedDetails"
      :key="`contact-${i}`"
      class="contacts__item"
    >
      <span class="h4 contacts__heading">
        <icon class="icon--item" :name="contact.icon" />
        {{ contact.title }}
      </span>
      <p
        :class="{
          'mb-0': i == parsedDetails.length - 1,
          contacts__value: true,
        }"
      >
        <a
          class="link--no-underline text-wrap"
          :href="contact.linkPrepend + contact.link"
        >
          {{ contact.link }}
        </a>
        <span v-if="contact.note"> {{ contact.note }} </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",

  props: {
    details: {
      required: true,
      type: Array,
    },
  },

  computed: {
    parsedDetails() {
      return this.details.map(this.parseContact);
    },
  },

  methods: {
    parseContact(detail) {
      const icons = {
        "Phone Number": "cabicons-phone",
        Email: "envelope-open",
        Website: "website",
      };

      const linkPrepends = {
        "Phone Number": "tel:",
        Email: "mailto:",
        Website: "",
      };

      return {
        icon: icons[detail.Type],
        title: detail.Type == "Phone Number" ? "Phone" : detail.Type,
        link: detail.Value,
        linkPrepend: linkPrepends[detail.Type],
        note: detail.Note,
      };
    },
  },
};
</script>

<style lang="scss">
@media print {
  .contacts__heading,
  .contacts__value,
  .contacts__item {
    break-inside: avoid;
    page-break-inside: avoid;
    display: inline-block !important;
  }

  .contacts__heading {
    margin-bottom: 0 !important;
    margin-right: rem(10px);
  }

  .contacts__value {
    margin-bottom: 0;
  }

  .contacts__item {
    width: 100%;
    margin-right: rem(10px);
  }
}
</style>
