<template>
  <div class="multiline-hero">
    <Hero :title="title" :withShape="false" :isCentered="true" />
    <div class="multiline-hero__inner">
      <div class="container">
        <div class="multiline-hero__content">
          <slot />
        </div>
      </div>
      <div class="multiline-hero__right-edge">
        <Koru class="multiline-hero__shape" />
        <div class="multiline-hero__right-stripe" />
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "./Hero";
import Koru from "../assets/search-koru.svg?inline";

export default {
  name: "MultilineHero",
  components: {
    Hero,
    Koru,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.multiline-hero {
  position: relative;

  .hero__title {
    padding-bottom: rem(16px);
    padding-right: 0;
    padding-left: 0;
  }

  .hero__right-edge {
    background-color: var(--color-bg-hero);
  }
}

.multiline-hero__inner {
  padding-top: rem(26px);
  padding-bottom: rem(26px);
  background-color: white;
}

.multiline-hero__right-edge {
  display: none;

  @media (min-width: 1228px) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: auto;
    padding: 0;
  }

  @include media-breakpoint-up(xxxl) {
    left: 80%;
    right: unset;
    width: 100%;
  }
}

.multiline-hero__right-stripe {
  display: none;

  @include media-breakpoint-up(xl) {
    display: block;
    height: 100%;
    background-color: var(--color-fill-shape-hero);
    flex: 1;
  }
}

.multiline-hero__shape {
  flex-shrink: 0;
  height: 100%;

  // Workaround firefox positioning bug
  @media (min-width: 1228px) {
    position: absolute;
    right: 0;
  }

  @include media-breakpoint-up(xxxl) {
    position: unset;
  }
  // End

  .inner {
    fill: white;
  }

  .outer {
    fill: var(--color-fill-shape-hero);
  }
}
</style>
