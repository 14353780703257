import dayjs from "dayjs";

export default {
  head: {
    title() {
      return {
        inner: this.metaTitle,
      };
    },
    // Meta tags
    meta() {
      return [
        { name: "description", content: this.metaDescription, id: "meta-desc" },
      ];
    },
  },
  computed: {
    url() {
      return window.location.toString();
    },

    lastCheckedDate() {
      if (this.page && this.page.LastChecked !== undefined) {
        return dayjs(this.page.LastChecked).format("YYYY-MM-DD HH:mm:ss");
      }
      return null;
    },

    lastCheckedFormatted() {
      if (this.page && this.page.LastChecked !== undefined) {
        return dayjs(this.page.LastChecked).format("D MMMM YYYY");
      }
      return null;
    },
  },
};
