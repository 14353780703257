// eslint-disable-next-line
import "./scss/main.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import VueHead from "vue-head";
import vClickOutside from "v-click-outside";
import VueSocialSharing from "vue-social-sharing";
// // vue awesome used in the community portal add more as needed
// import "vue-awesome/icons/caret-down";
import "vue-awesome/icons/caret-right";
import IconData from "./icons";
import IconCAB from "./cab-icons";
// TODO: for now load all icons - look into loading only the icons that are used
// see issue https://github.com/Justineo/vue-awesome/issues/135
import Icon from "vue-awesome";
const axios = require("axios");
import tableDirective from "./directives/table";
import alertDirective from "./directives/alert";

// Custom icons
Icon.register({ ...IconData, ...IconCAB });

// Custom directives
Vue.directive("mobile-table", tableDirective);
Vue.directive("alert", alertDirective);

Vue.config.productionTip = false;

const mountEl = document.querySelector("#app");

// Info from the CMS
Vue.prototype.$appSettings = JSON.parse(
  mountEl.getAttribute("data-app-settings")
);
Vue.prototype.$cmsSlug = mountEl.getAttribute("data-cms-slug");
Vue.prototype.$loggedIn = mountEl.getAttribute("data-logged-in");
Vue.prototype.$cmsServerError = mountEl.getAttribute("data-server-error").length
  ? JSON.parse(mountEl.getAttribute("data-server-error"))
  : "";
Vue.prototype.$cmsNotFound = mountEl.getAttribute("data-not-found").length
  ? JSON.parse(mountEl.getAttribute("data-not-found"))
  : "";

// Set Axios defaults
axios.defaults.headers.common["x-requested-with"] = "XMLHttpRequest";
axios.defaults.headers.common["SECURITY-TOKEN"] =
  Vue.prototype.$appSettings.SecurityToken;

Vue.prototype.$axios = axios;

Vue.component("icon", Icon);
Vue.use(VueSocialSharing);
Vue.use(vClickOutside);
Vue.use(VueHead, {
  separator: "|",
  complement: Vue.prototype.$appSettings
    ? Vue.prototype.$appSettings.SiteTitle || "1"
    : "2",
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
