/**
 * determines if the width of a table is greater than the width of the table holder
 */
const setupScrollable = (tableHolders) => {
  for (let i = 0; i < tableHolders.length; i++) {
    let tableHolder = tableHolders[i];
    let scrollWidth = tableHolder.scrollWidth;
    let divWidth = tableHolder.offsetWidth;

    if (scrollWidth > divWidth) {
      tableHolder.classList.add("show-arrow-right");
      tableHolder.addEventListener("scroll", detectTableScrollPos);
    } else {
      tableHolder.classList.remove("show-arrow-right");
      tableHolder.classList.remove("show-arrow-left");
      tableHolder.removeEventListener("scroll", detectTableScrollPos);
    }
  }
};

/**
 * When a table is scrollable this function determines if the the left/right arrows should be shown
 * (to inform the user the table is scrollable)
 */
const detectTableScrollPos = (ev) => {
  const tableHolder = ev.target;
  const scrollLeft = tableHolder.scrollLeft;
  const scrollWidth = tableHolder.scrollWidth;
  const divWidth = tableHolder.offsetWidth;
  if (scrollLeft === 0) {
    tableHolder.classList.add("show-arrow-right");
  } else if (scrollWidth === scrollLeft + divWidth) {
    tableHolder.classList.add("show-arrow-left");
  } else {
    tableHolder.classList.remove("show-arrow-right");
    tableHolder.classList.remove("show-arrow-left");
  }
};

export default {
  inserted: function (el) {
    // Wrap tables
    let tables = el.querySelectorAll("table");
    tables.forEach((table) => {
      table.outerHTML =
        "<div class='table-holder'><div class='table-holder__inner'>" +
        table.outerHTML +
        "</div></div>";
    });

    let tableHolders = document.querySelectorAll(".table-holder__inner");
    setupScrollable(tableHolders);
    window.addEventListener("resize", () => setupScrollable(tableHolders));
  },
};
