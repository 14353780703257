<template>
  <div class="contact-details">
    <h2 class="h3">
      <icon
        name="cabicons-contact-details"
        scale="1.25"
        class="icon--heading"
      />Contact details
    </h2>
    <div class="contact-details__inner">
      <div class="contact-details__details">
        <div v-for="(detail, i) in details" :key="`detail-${i}`">
          <span class="h4">{{ detail.title }}</span>
          <p v-html="detail.content"></p>
        </div>
      </div>

      <Contacts :details="contacts" class="contact-details__contacts" />
    </div>
  </div>
</template>

<script>
import Contacts from "./Contacts";
export default {
  name: "ContactDetails",
  components: { Contacts },

  props: {
    details: Array,
    contacts: Array,
  },
};
</script>

<style lang="scss">
.contact-details {
  &__inner {
    @include media-breakpoint-up(sm) {
      display: flex;

      & > * {
        flex: 1;
      }
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

@media print {
  .contact-details__details > * {
    page-break-inside: avoid;
  }
}
</style>
