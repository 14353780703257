<template>
  <div
    class="critical-alert hidden-print"
    :style="'background-color:' + backgroundColor + ';'"
  >
    <div class="container critical-alert-container">
      <div v-html="alert.Content" class="critical-alert-content"></div>
      <div v-if="showButton" class="critical-alert-button-container">
        <a :href="button.Link.Link" class="btn button button--small">{{
          button.Text
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    alert() {
      return this.$appSettings.CriticalAlert;
    },
    showButton() {
      return this.alert.Button.Text && this.alert.Button.Text;
    },
    button() {
      return this.alert.Button;
    },
    backgroundColor() {
      const color = "#" + this.alert.Color;
      return color.replace(/##/, "#"); // Ensures it's only one # incase it was already added in the CMS.
    },
  },
};
</script>

<style lang="scss">
.critical-alert {
  overflow: hidden;
  padding: 12px 24px;
}

.critical-alert-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.critical-alert-content {
  *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.critical-alert-button-container {
  margin-top: 12px;
  margin-left: 12px;
  @include media-breakpoint-up(sm) {
    margin-top: 0;
    margin-left: 0;
  }

  .button {
    white-space: nowrap;
  }
}
</style>
