<template>
  <div class="share">
    <div class="h4 share__heading" v-if="heading">{{ heading }}</div>
    <ShareNetwork
      :url="url"
      :title="title"
      :description="description"
      :network="network"
      v-for="network in networks"
      :key="network"
      class="share__item"
    >
      <span class="sr-only">Share on {{ getNetworkLabel(network) }}</span>
      <icon :name="network" class="share__icon"></icon>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "SocialSharing",
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    heading: {
      type: String,
      default: "Share this article",
    },
  },
  data() {
    return {
      networks: ["facebook", "twitter", "email"],
    };
  },
  methods: {
    getNetworkLabel(network) {
      if (network === 'twitter') {
        return 'X.com';
      }

      return network;
    }
  }
};
</script>

<style lang="scss">
.share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.share__heading {
  font-weight: bold;
  font-size: rem(16px);
  letter-spacing: rem(0.17px);
  margin: 0 rem(20px) 0 0;

  @include media-breakpoint-up(md) {
    font-size: rem(18px);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(16px);
  }

  .theme--font-avenir & {
    font-weight: 900;
  }
}

.share__item {
  color: $theme-whio-primary-color;

  &:not(:last-child) {
    margin-right: rem(20px);
  }

  &:hover {
    color: $theme-whio-hover-color;
    text-decoration: none;
  }
}

.share__icon {
  width: 1.5em;
  height: 1.5em;
}

@media print {
  .share {
    display: none;
  }
}
</style>
