<template>
  <div class="multiselect" :class="{ 'multiselect--checkboxes': !searchable }">
    <select :id="id" :multiple="multiple" :disabled="disabled">
      <option v-if="placeholder" data-placeholder="true"></option>
      <option
        v-for="option in options"
        :key="option.id || option.value"
        :value="option.value"
        :selected="isSelected(value, option.value)"
        v-html="encapsulateWord(option.text)"
      />
    </select>
    <icon ref="closeIcon" v-if="!slimSelectCreated" name="close" />
  </div>
</template>

<script>
import SlimSelect from "slim-select";
import { isEqual } from "lodash";

export default {
  props: {
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    allowDeselect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: `multiselect-${this._uid}`,
      slimSelect: null,
      slimSelectCreated: false,
    };
  },

  mounted() {
    this.slimSelect = new SlimSelect(this.slimSelectConfig());
    this.slimSelectCreated = true;

    if (this.value) {
      this.slimSelect.set(this.value);
    }
  },

  methods: {
    encapsulateWord(str) {
      // required in order not to break a word that contains a piece of the search
      return str
        .split(" ")
        .map((el) => "<span>" + el + "</span>")
        .join(" ");
    },
    isSelected(value, optionValue) {
      if (!value) {
        return false;
      }

      if (!this.multiple) {
        return value.toString() === optionValue;
      } else {
        if (Array.isArray(value)) {
          if (value.indexOf(optionValue) >= 0) {
            return true;
          }

          return false;
        }
      }
    },

    slimSelectConfig() {
      let options = {
        select: "#" + this.id,
        placeholder: this.placeholder,
        onChange: this.onChange,
        beforeOnChange: this.beforeOnChange,
        searchHighlight: this.searchable ? true : false,
        showContent: "down",
        allowDeselect: this.allowDeselect,
        showSearch: this.searchable,
        searchPlaceholder: "",
        deselectLabel: "",
      };

      if (this.searchable && this.search && typeof this.search === "function") {
        options.ajax = this.onSearch;
      }

      return options;
    },

    onSearch(query, callback) {
      this.search(query, callback);
    },

    onChange(option) {
      if (this.multiple) {
        if (option) {
          const values = option.map((p) => {
            return p.value;
          });

          if (!isEqual(this.value, values)) {
            this.$emit("input", values);

            if (!values.length) {
              this.$emit("empty");
            }
          }
        } else {
          if (this.value != null) {
            this.$emit("input", null);
            this.$emit("empty");
          }
        }
      } else {
        this.$emit("input", option.value);
        if (!option.value) {
          this.$emit("empty");
        }
      }

      // Get placeholder element
      if (this.slimSelect.slim.singleSelected) {
        const element = this.slimSelect.slim.singleSelected.placeholder;
        // Ensure the active class is removed
        element.classList.remove("ss-active-selected");
        // If the dropdown option is selected then add active class
        if (element && option.value) {
          element.classList.add("ss-active-selected");
        }
      }
    },

    beforeOnChange(option) {
      if (this.multiple) {
        if (option.length) {
          this.$emit(
            "input",
            option.map((p) => {
              return p.value;
            })
          );
        } else {
          this.$emit("input", option);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  @import "../../node_modules/slim-select/src/slim-select/slimselect.scss";

  .ss-main {
    .ss-single-selected,
    .ss-multi-selected {
      min-height: 45px;
      border-radius: 7px !important;
      padding-left: 15px;
      border-color: $color-black-80;

      .placeholder {
        color: $color-body;
        line-height: 1.6;

        @media print {
          display: none;
        }

        .ss-disabled {
          color: $gray-dark;
        }
      }

      .ss-arrow {
        span {
          border-color: var(--color-primary);
        }

        @media print {
          display: none;
        }
      }

      .ss-add {
        .ss-plus {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &:after {
            background: var(--color-primary);
          }

          &.ss-cross {
            top: 18px;
          }
        }
      }

      .ss-values {
        .ss-disabled {
          color: $gray-dark;
        }

        .ss-value {
          background-color: var(--color-primary);
          color: $white;
          font-size: 1rem;
        }
      }
    }

    .ss-content {
      $content-margin: rem(8px);

      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      margin-top: $content-margin;

      .ss-search {
        position: absolute;
        bottom: calc(100% + #{$content-margin} + 2px);
        width: calc(100% - #{rem(55px)});

        input {
          box-shadow: none !important;
          border-color: transparent;
        }
      }

      .ss-list {
        border-radius: 7px;

        .ss-option {
          padding: rem(12px) $base-spacing-secondary;
          align-items: center;

          &.ss-hide {
            display: none;
          }

          &:hover,
          &.ss-highlighted {
            color: $color-body;
            background-color: $gray-lightest;
          }

          &.ss-disabled {
            color: $color-body;
            background-color: $blue-lightest;
          }

          .ss-search-highlight {
            background-color: unset;
            font-weight: bold;
          }
        }
      }
    }
  }

  &--checkboxes {
    .ss-single-selected {
      .ss-active-selected::before {
        display: inline-block;
        content: "";
        margin-right: rem(8px);
        background-position: center center;
        background-size: cover;
        background-image: url("../assets/checkbox-no-fill-checked.svg");
        background-color: var(--color-primary);
        width: rem(16px);
        height: rem(16px);
        border-radius: 3px;
        border: none;
        margin-top: rem(-2px);
      }
    }

    .ss-option {
      &::before {
        display: inline-block;
        width: rem(15px);
        height: rem(15px);
        border: 1px solid $color-black-80;
        border-radius: 3px;
        margin-right: rem(8px);
        background-image: url("../assets/checkbox-unchecked.svg");
        background-position: center center;
        background-size: cover;
        content: "";
      }

      &.ss-disabled,
      &.ss-option-selected {
        background-color: unset !important;

        &::before {
          background-image: url("../assets/checkbox-no-fill-checked.svg");
          background-color: var(--color-primary);
          width: rem(16px);
          height: rem(16px);
          border-radius: 3px;
          border: none;
        }
      }
    }
  }

  .ss-deselect {
    background-image: url("../assets/close.svg");
    background-size: cover;
    background-position: center;
    text-indent: -1000px;
    width: rem(16px);
    height: rem(16px);
    align-self: center;
    overflow: hidden;
    margin: 0 rem(16px) !important;
  }

  .ss-active-selected span {
    overflow: unset !important;
    margin-right: 3px;
  }
}
</style>
