<template>
  <div class="contact-person">
    <h2 class="h3">
      <icon name="cabicons-contact-person" scale="1.6" class="icon--heading" />
      Contact person
    </h2>
    <p>
      <b>{{ person.name }}</b>
      <br />
      <span v-if="person.jobTitle">{{ person.jobTitle }}</span>
    </p>
    <Contacts :details="person.contacts" />
  </div>
</template>

<script>
import Contacts from "./Contacts";
export default {
  name: "ContactDetails",
  components: { Contacts },
  props: {
    person: Object,
  },
};
</script>
