<template>
  <nav class="breadcrumb" v-if="breadcrumbs && breadcrumbs.length">
    <nav class="container breadcrumb__inner" aria-label="You are here:">
      <ul class="breadcrumb__list">
        <li
          v-for="(item, index) in breadcrumbs"
          :key="`b-${index}`"
          class="breadcrumb__item"
        >
          <a
            class="breadcrumb__link"
            :href="item.Url"
            @click.prevent.exact="navigateTo(item.Url)"
            v-if="index !== breadcrumbs.length - 1"
            >{{ item.Title }}</a
          >
          <span v-else class="breadcrumb__link"
            ><span class="sr-only">Current: </span>{{ item.Title }}</span
          >

          <span
            aria-hidden="true"
            class="breadcrumb__sep"
            v-if="index !== breadcrumbs.length - 1"
            >&nbsp;&#47;&nbsp;</span
          >
        </li>
      </ul>
    </nav>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    navigateTo(url) {
      if (url.startsWith('http')) {
        // If the URL is absolute, use window.location.href
        window.location.href = url;
      } else {
        // If the URL is relative, use Vue Router
        this.$router.push(url.startsWith('/') ? url : `/${url}`);
      }
    },
  },
};
</script>

<style lang="scss">
.breadcrumb {
  background: $color-background;
  color: $color-body;
  font-size: rem(16px);
  line-height: rem(28px);
  font-weight: 300;
  letter-spacing: rem(0.24px);
  padding: rem(10px) 0;
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumb__item {
  display: inline-block;
}

.breadcrumb__sep {
  display: inline-block;
  margin: 0 rem(6px);
}

.breadcrumb__link {
  display: inline-block;
  text-decoration: none;

  &,
  &:visited {
    color: $color-body;
  }

  &:not(span):hover {
    text-decoration: underline;
  }
}

@media print {
  .breadcrumb {
    display: none;
  }
}
</style>
