<template>
  <article id="site-main" class="site-main" role="main">
    <Loader v-if="isLoading" />
    <ErrorPage v-else-if="errorNotFound || errorServerError" />
    <div v-else class="home">
      <div class="home__inner">
        <picture class="home__hero">
          <source :srcset="page.Hero.desktop" media="(min-width: 768px)" />
          <img
            class="home__hero-img"
            :src="page.Hero.mobile"
            :alt="page.Hero.alt"
          />
        </picture>
        <div class="home__hero-content">
          <div class="home__message">
            <h1
              v-if="page.WelcomeMessage"
              v-html="replaceParentheses(page.WelcomeMessage)"
            />
          </div>
          <SimpleSearch
            v-if="page.Search.enabled && $appSettings.Theme.SearchBar"
            :visible="true"
            :label="page.Search.label"
            :placeholder="page.Search.placeholder"
            :is-label-visible="true"
            :is-collapsible="false"
            class="home__search"
          />
        </div>
        <div v-if="page.Introduction" class="h3 home__intro">
          {{ page.Introduction }}
        </div>
        <div
          v-if="page.Categories && page.Categories.length"
          class="container home__categories"
        >
          <a
            v-for="(category, index) in page.Categories"
            :key="`cat-${index}`"
            class="btn home__category-btn"
            :href="category.link"
            :target="category.newWindow ? '_blank' : '_self'"
          >
            <span class="home__category-icon" aria-hidden="true">
              <icon v-if="category.icon" :name="category.icon" />
            </span>
            <span class="home__category-label">{{ category.title }}</span>
          </a>
        </div>
        <div class="home__info">
          <div class="container home__info-inner">
            <h2
              v-if="page.Info.title"
              class="h1 home__info-title"
              v-html="replaceParentheses(page.Info.title)"
            />
            <p class="home__info-intro">{{ page.Info.introduction }}</p>
            <a
              v-if="page.Info.cta.title"
              class="btn"
              :href="page.Info.cta.link"
              :target="page.Info.cta.newWindow ? '_blank' : '_self'"
              >{{ page.Info.cta.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import metaMixin from "@/mixins/meta";
import errorMixin from "@/mixins/error-page";
import fetchPageMixin from "@/mixins/fetch-page";
import get from "lodash/get";
import ErrorPage from "@/components/Error";
import SimpleSearch from "@/components/SimpleSearch";

export default {
  name: "Home",

  components: {
    ErrorPage,
    SimpleSearch,
  },

  mixins: [metaMixin, errorMixin, fetchPageMixin],

  created() {
    this.fetchPageData();
  },

  computed: {
    metaTitle() {
      return get(this.page, "Title", "Loading content");
    },

    metaDescription() {
      return get(this.page, "MetaDescription", "");
    },
  },
  methods: {
    // each letter within () e.g he(l))lo will return he<span style='color: red;'>l</span>lo
    replaceParentheses(string) {
      const regex = new RegExp(/(\()(\w)(\))/, "gm");
      return string.replace(
        regex,
        (match, p1, p2) => "<span class='font-brizo'>" + p2 + "</span>"
      );
    },
  },
};
</script>

<style lang="scss">
$category-space: 12px;
$category-icon-size: 48px;
$hero-height-mobile: 373px;
$hero-height-desktop: 400px;

.home {
  //
}

.home__inner {
  position: relative;
}

.home__hero {
  height: $hero-height-mobile;
  position: absolute;
  left: 0;
  right: 0;

  @include media-breakpoint-up(lg) {
    height: $hero-height-desktop;
  }

  &::after {
    height: 42%;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(50, 50, 50, 0) 0%,
      var(--color-bg-home-hero-gradient) 100%
    );

    @include media-breakpoint-up(lg) {
      height: 50%;
    }
  }

  &-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-content {
    min-height: $hero-height-mobile;
    position: relative;
    margin: auto;

    @include media-breakpoint-up(lg) {
      min-height: $hero-height-desktop;
    }

    > * {
      padding: 0 $gutter;
    }
  }
}

.home__message {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 13px;
  margin: auto;
  height: $hero-height-mobile;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    padding-bottom: 16px;
    font-size: 40px;
    letter-spacing: 0.13px;
    height: $hero-height-desktop - 73px;
    max-width: 900px;
  }
}

.home__search {
  margin: auto;
  position: relative;
  background-color: var(--color-bg-home-search);
  padding: 0;

  @include media-breakpoint-up(lg) {
    border-radius: 7px;
    width: 792px;
  }

  .simple-search__form {
    flex-wrap: wrap;
    height: auto;
    padding: 16px 16px;

    @include media-breakpoint-up(sm) {
      padding: 24px;
      margin: 0 auto;
    }
  }

  .simple-search__input {
    height: 46px;
  }

  label {
    color: $color-body;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.17px;
    line-height: 1.4;
    width: 100%;
    margin-bottom: 16px;
  }
}

.home__intro {
  color: $color-body;
  font-weight: 900;
  line-height: 1.4;
  text-align: center;
  max-width: 792px;
  margin: 22px auto 24px;
  padding: 0 $gutter;

  @include media-breakpoint-up(sm) {
    padding: 0 24px;
  }
}

.home__categories {
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: -1 * $category-space;
}

.home__category-btn {
  width: 100%;
  min-height: 80px;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  background-color: #fff;
  margin: $category-space $category-space 4px;
  color: $color-body;

  @include media-breakpoint-up(md) {
    width: 50%;
    flex: 0 0 calc(50% - #{$category-space} * 2);
  }

  @include media-breakpoint-up(lg) {
    width: 33.333%;
    flex: 0 0 calc(33.333% - #{$category-space} * 2);
  }

  &:hover {
    color: var(--color-fg-home-category-hover);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;

    .home__category-icon {
      transform: scale(1.17);
    }
  }
}

.home__category-icon {
  width: $category-icon-size;
  height: $category-icon-size;
  border-radius: 50%;
  background-color: var(--color-bg-home-category-icon);
  margin-right: 16px;
  display: grid;
  place-content: center;
  flex: 0 0 $category-icon-size;

  svg {
    color: #fff;
    width: $category-icon-size / 2;
    height: $category-icon-size / 2;
  }
}

.home__category-label {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.17px;
  line-height: 1.4;
}

.home__info {
  border-top: 12px solid var(--color-br-home-info);
  text-align: center;
  background-color: $light-blue;
  background-image: var(--image-bg-home-info);
  padding: 28px 0 40px 0;

  @include media-breakpoint-up(lg) {
    padding-top: 33px;
  }

  &-title {
    font-size: 28px;
    color: var(--color-fg-home-info-title);
    font-weight: 900;
    line-height: 40px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  &-intro {
    color: $color-body;
    letter-spacing: 0.24px;
    line-height: 1.8;
    text-align: center;
    margin: 8px auto 24px;

    @include media-breakpoint-up(sm) {
      max-width: 70%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
  }
}
</style>
