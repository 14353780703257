<template>
  <div class="search-locations">
    <Map
      ref="map"
      class="search-locations__map"
      :zoom="14"
      :interactive="false"
      :hide-static="true"
      @mapLoaded="centerMap"
      @click="activeResult = null"
    >
      <template slot-scope="{ map }">
        <MapMarker
          :map="map"
          v-for="(result, i) in results"
          :key="`result-${i}`"
          :clickable="true"
          :feature="result"
          :is-active="activeResult && activeResult.code == result.code"
          @click="activeResult = result"
        />
      </template>
      <template #content>
        <MapResult
          v-if="activeResult"
          :result="activeResult"
          @close="activeResult = null"
        />
      </template>
    </Map>
  </div>
</template>

<script>
import Map from "@/components/Map/Map";
import MapMarker from "@/components/Map/MapMarker";
import MapResult from "@/components/Map/MapResult";

const MAX_INIT_ZOOM = 18;

export default {
  name: "SearchLocations",
  components: {
    Map,
    MapMarker,
    MapResult,
  },

  props: {
    results: Array,
  },

  data() {
    return {
      showInfo: false,
      activeResult: null,
    };
  },

  methods: {
    centerMap() {
      const map = this.$refs.map.map;

      const bounds = new google.maps.LatLngBounds();

      this.results.forEach((result) => {
        bounds.extend(result.location);
      });

      map.fitBounds(bounds);

      google.maps.event.addListenerOnce(map, "idle", function () {
        if (map.getZoom() > MAX_INIT_ZOOM) {
          map.setZoom(MAX_INIT_ZOOM);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.search-locations__map {
  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;

  .map {
    height: 100%;
    min-height: rem($map-search-height);
  }
}

@media print {
  //
}
</style>
