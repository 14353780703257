<template>
  <div>
    <div class="map hide-print" ref="map"></div>
    <template v-if="map">
      <slot :map="map" />
    </template>
    <img
      v-if="!hideStatic"
      :src="staticMapUrl"
      class="map__static show-print"
    />
    <template v-if="$slots.content">
      <div class="map__content-container">
        <div class="map__content">
          <slot name="content" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  props: {
    center: Object,
    zoom: {
      type: Number,
      default: 15,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    hideStatic: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      apiKey: "AIzaSyCCij3uwNVbcLLV77z8lycwmRWeJk2P7JE",
      map: null,
    };
  },

  computed: {
    mapConfig() {
      return {
        center: this.center,
        zoom: this.zoom,
        disableDefaultUI: !this.interactive,
      };
    },

    staticMapUrl() {
      const color = this.$appSettings.Theme?.Colors.Primary || "#323232";

      let url = "https://maps.googleapis.com/maps/api/staticmap";
      url += "?zoom=18&size=600x300&maptype=roadmap";
      url += `&markers=color:${color.replace("#", "0x")}|${this.center.lat},${
        this.center.lng
      }`;
      url += `&key=${this.apiKey}`;

      return url;
    },
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
    });

    await loader.load();

    this.initMap();
  },

  methods: {
    initMap() {
      const mapContainer = this.$refs.map;
      /* global google */
      this.map = new google.maps.Map(mapContainer, this.mapConfig);

      const mapListeners = ["click"];

      Object.keys(this.$listeners)
        .filter((handle) => mapListeners.includes(handle))
        .forEach((handle) => {
          this.map.addListener(handle, (event) => {
            this.$emit(handle, { event, map: this.map });
          });
        });

      this.$emit("mapLoaded");
    },
  },
};
</script>

<style lang="scss">
.map {
  height: rem(372px);
}

.map__content-container {
  position: absolute;
  top: 0;
  width: 1440px;
  max-width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.map__content {
  pointer-events: all;
}

@media print {
  .map__static {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
