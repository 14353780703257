<template>
  <div class="location-switcher">
    <button
      class="btn btn--has-icon location-switcher__btn"
      @click.prevent="showResult()"
      :class="{
        'btn--inactive': isMapActive,
      }"
    >
      <icon name="bars" class="btn__icon btn__icon--left btn__icon--md" />
      List view
    </button>
    <button
      class="btn btn--has-icon location-switcher__btn"
      :class="{
        'btn--inactive': isListActive,
      }"
      @click.prevent="showMap()"
    >
      <icon
        name="map-marker-alt"
        class="btn__icon btn__icon--left btn__icon--md"
      />
      Map view
    </button>
  </div>
</template>

<script>
export const TYPE_MAP = "map";
export const TYPE_LIST = "list";

export default {
  name: "SearchViewSwitcher",

  props: {
    initialValue: {
      type: String,
      validator(value) {
        return [TYPE_MAP, TYPE_LIST].includes(value);
      },
      default: TYPE_LIST,
    },
  },

  data() {
    return {
      current: this.initialValue,
    };
  },

  computed: {
    isListActive() {
      return this.current === TYPE_LIST || !this.current;
    },

    isMapActive() {
      return this.current === TYPE_MAP;
    },
  },

  methods: {
    showMap() {
      this.current = TYPE_MAP;
      this.$emit("change", this.current);
    },

    showResult() {
      this.current = TYPE_LIST;
      this.$emit("change", this.current);
    },
  },
};
</script>

<style lang="scss">
.location-switcher {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  &__btn {
    margin-right: 0;
    margin-left: 0;

    // Enforce of border-radius is added so that the button theme does not style these elements
    &:first-child {
      border-radius: 7px 0 0 7px !important;
    }

    &:last-child {
      border-radius: 0 7px 7px 0 !important;
    }

    &:not(.btn--inactive) {
      border-color: var(--color-bg-btn);

      &:hover {
        --color-bg-hover-btn: var(--color-bg-btn);
        cursor: default;
      }
    }

    &.btn--inactive {
      &:hover {
        background: #323232;
      }
    }
  }

  .btn__left-icon {
    width: 0.8em;
  }
}

@media print {
  .location-switcher {
    display: none;
  }
}
</style>
