export default {
  menu: {
    width: "24",
    height: "24",
    paths: [
      {
        style: "fill:currentColor",
        d: "M1 3.5 H23 V6.5 H1 V3.5 z",
      },
      {
        style: "fill:currentColor",
        d: "M1 10.5 H23 V13.5 H1 V10.5 z",
      },
      {
        style: "fill:currentColor",
        d: "M1 17.5 H23 V20.5 H1 V17.5 z",
      },
    ],
  },
  close: {
    width: "25",
    height: "25",
    paths: [
      {
        transform:
          "translate(12.000000, 12.000000) rotate(45.000000) translate(-12.000000, -12.000000)",
        style: "fill:currentColor",
        d: "M1 10.5 H23 V13.5 H1 V10.5 z",
      },
      {
        style: "fill:currentColor",
        transform:
          "translate(12.000000, 12.000000) scale(-1, 1) rotate(45.000000) translate(-12.000000, -12.000000)",
        d: "M1 10.5 H23 V13.5 H1 V10.5 z",
      },
    ],
  },
  search: {
    width: "25",
    height: "25",
    paths: [
      {
        style: "fill:currentColor",
        d:
          "M9.43281875,14.5786164 C6.58547742,14.5786164 4.28702115,12.2801601 4.28702115,9.43281875 C4.28702115,6.58547742 6.58547742,4.28702115 9.43281875,4.28702115 C12.2801601,4.28702115 14.5786164,6.58547742 14.5786164,9.43281875 C14.5786164,12.2801601 12.2801601,14.5786164 9.43281875,14.5786164 L9.43281875,14.5786164 Z M16.2938822,14.5786164 L15.3905089,14.5786164 L15.0703259,14.2698685 C16.1909663,12.9662664 16.8656375,11.2738708 16.8656375,9.43281875 C16.8656375,5.32761578 13.5380217,2 9.43281875,2 C5.32761578,2 2,5.32761578 2,9.43281875 C2,13.5380217 5.32761578,16.8656375 9.43281875,16.8656375 C11.2738708,16.8656375 12.9662664,16.1909663 14.2698685,15.0703259 L14.5786164,15.3905089 L14.5786164,16.2938822 L20.2961692,22 L22,20.2961692 L16.2938822,14.5786164 Z",
      },
    ],
  },
  location: {
    width: 34,
    height: 42,
    d:
      "M20,0 C28.939711,0 36.2,7.02290451 36.2,15.7030165 C36.2,20.7981155 32.1543171,27.8940562 24.1070712,37.1390239 C24.0228189,37.2361542 23.9625764,37.30563 23.913612,37.3617333 C23.8229505,37.4656131 23.739499,37.5599499 23.653436,37.6554082 C23.4265183,37.907098 23.2044878,38.1407618 22.9767895,38.3635698 C22.4824255,38.8473162 22.0030781,39.2393539 21.5265041,39.5218143 C21.0127867,39.8262892 20.5093476,40 20,40 C19.4954546,40 18.9967032,39.8299428 18.4885409,39.5315668 C18.0067178,39.2486563 17.5226452,38.8535365 17.0232039,38.3644537 C16.7971699,38.1431077 16.5767516,37.9110274 16.3511988,37.6607851 C16.2656329,37.5658529 16.1826148,37.4719922 16.0922095,37.3684038 C16.0436654,37.3127809 15.9832594,37.2431231 15.8988294,37.1458022 C7.84764689,27.8975162 3.8,20.7993413 3.8,15.7030165 C3.8,7.02290451 11.060289,0 20,0 Z M20,9.46819887 C16.3510427,9.46819887 13.4,12.3680638 13.4,15.9363977 C13.4,19.5047316 16.3510427,22.4045966 20,22.4045966 C23.6489573,22.4045966 26.6,19.5047316 26.6,15.9363977 C26.6,12.3680638 23.6489573,9.46819887 20,9.46819887 Z",
  },
  "cabicons-contact-details": {
    width: 16,
    height: 20,
    d:
      "m 7.51311,18.1326 c -0.004,0 -0.007,0 -0.011,0.01 0.0102,-0.01 0.0227,-0.014 0.0373,-0.025 z M 8.24477,17.3975 C 12.11057,12.9563 14,9.6424 14,7.7384 14,4.557 11.3207,1.9653 8,1.9653 c -3.3207,0 -6,2.5917 -6,5.7731 0,1.9045 1.89043,5.2197 5.75823,9.6626 0.10852,0.1246 0.18196,0.2085 0.24171,0.2747 0.0606,-0.067 0.13482,-0.1518 0.24483,-0.2782 z M 8,20 C 7.35819,20 6.51975,19.0017 6.23845,18.6785 2.07948,13.9012 0,10.2545 0,7.7384 0,3.4646 3.58172,0 8,0 c 4.41828,0 8,3.4646 8,7.7384 0,2.5155 -2.07846,6.161 -6.23537,10.9366 C 9.48539,18.9958 8.64181,20 8,20 Z",
  },
  "cabicons-phone": {
    width: 16,
    height: 16,
    d:
      "m 15.40586,0.7713 c 0.18762,0.021 0.33355,0.1043 0.43779,0.2502 C 15.94788,1.1674 16,1.3238 16,1.4906 16,4.1173 15.34332,6.5564 14.02997,8.8078 12.73746,10.9967 10.99674,12.7375 8.80782,14.03 6.55635,15.3433 4.11726,16 1.49055,16 1.32378,16 1.16743,15.948 1.0215,15.8437 0.87557,15.7394 0.79218,15.5935 0.77134,15.4059 L 0.02085,12.1538 C -0.02085,11.987 5e-5,11.8202 0.08335,11.6534 0.16675,11.4867 0.29182,11.372 0.45859,11.3095 l 3.50228,-1.501 c 0.14593,-0.063 0.30228,-0.078 0.46906,-0.047 0.16677,0.031 0.30228,0.1198 0.40651,0.2658 l 1.53225,1.8762 c 1.22997,-0.5629 2.31922,-1.3238 3.26775,-2.2827 0.94854,-0.959 1.70424,-2.0431 2.2671,-3.2522 L 10.02732,4.8051 C 9.90224,4.7009 9.81885,4.5706 9.77716,4.4142 9.73546,4.2579 9.74586,4.0963 9.80846,3.9295 l 1.50098,-3.5022 c 0.0625,-0.146 0.17719,-0.2606 0.34397,-0.344 0.16677,-0.083 0.33355,-0.1043 0.50033,-0.062 z",
  },
  "cabicons-description": {
    width: 22,
    height: 28,
    d:
      "M 4,2 V 1 C 4,0.4477 4.44772,0 5,0 h 12 c 0.55228,0 1,0.4477 1,1 v 1 h 3 c 0.55228,0 1,0.4477 1,1 v 18 c -0.001,0.2648 -0.10721,0.5183 -0.295,0.705 l -6,6 C 15.51832,27.8928 15.26479,27.9989 15,28 H 3 C 1.34315,28 0,26.6569 0,25 V 3 C 0,2.4477 0.44772,2 1,2 Z M 4,4 H 2 v 21 c 0,0.5523 0.44772,1 1,1 h 11 v -3 c 0,-1.6568 1.34315,-3 3,-3 h 3 V 4 h -2 v 1 c -0.001,0.2648 -0.10721,0.5183 -0.295,0.705 l -2,2 C 15.51832,7.8928 15.26479,7.9989 15,8 H 7 C 6.73521,8 6.48167,7.8928 6.295,7.705 l -2,-2 C 4.10721,5.5183 4.00112,5.2648 4,5 Z M 16,4.585 V 2 H 6 V 4.585 L 7.415,6 h 7.17 z M 5,11 h 12 c 0.55228,0 1,0.4477 1,1 0,0.5523 -0.44772,1 -1,1 H 5 C 4.44772,13 4,12.5523 4,12 4,11.4477 4.44772,11 5,11 Z m 0,4 h 12 c 0.55228,0 1,0.4477 1,1 0,0.5523 -0.44772,1 -1,1 H 5 C 4.44772,17 4,16.5523 4,16 4,15.4477 4.44772,15 5,15 Z m 0,4 h 6 c 0.55228,0 1,0.4477 1,1 0,0.5523 -0.44772,1 -1,1 H 5 C 4.44772,21 4,20.5523 4,20 4,19.4477 4.44772,19 5,19 Z",
  },
  "cabicons-contact-person": {
    width: 20,
    height: 24,
    d:
      "m 6.25,5.8279 c 0,2.0969 1.68096,3.8008 3.7498,3.8008 2.06944,0 3.7502,-1.7037 3.7502,-3.8008 0,-2.0977 -1.68045,-3.8008 -3.7502,-3.8008 -2.06916,0 -3.7498,1.7033 -3.7498,3.8008 z m -2,0 C 4.25,2.6107 6.82629,0 9.9998,0 13.17411,0 15.75,2.6107 15.75,5.8279 c 0,3.2164 -2.57589,5.8278 -5.7502,5.8278 C 6.82629,11.6557 4.25,9.0443 4.25,5.8279 Z M 18,24 v -0.1767 c 0,-2.4122 -0.79199,-4.1644 -2.26124,-5.3146 -1.37459,-1.0759 -3.35686,-1.6318 -5.73762,-1.6318 -2.38241,0 -4.36611,0.5561 -5.73881,1.6318 C 2.79357,19.6582 2,21.4133 2,23.8233 V 24 H 0 v -0.1767 c 0,-3.1167 1.13257,-5.4262 3.04,-6.919 1.8621,-1.4592 4.35238,-2.0545 6.96114,-2.0545 2.60762,0 5.09562,0.5953 6.96,2.0545 C 18.86895,18.3979 20,20.7042 20,23.8233 V 24 Z",
  },
  facebook: {
    width: "24",
    height: "24",
    paths: [
      {
        style: "fill:currentColor",
        transform:
          "translate(-1164.000000, -303.000000) translate(0.000000, 120.000000) translate(1070.000000, 182.000000) translate(94.000000, 1.000000)",
        d:
          "M12,2.29150032e-13 C5.37303323,2.29150032e-13 -1.77635684e-15,5.40568058 -1.77635684e-15,12.0729138 C-1.77635684e-15,18.0987229 4.38749735,23.0938239 10.1246031,24.0001655 L10.1246031,15.5636921 L7.07824737,15.5636921 L7.07824737,12.0729138 L10.1246031,12.0729138 L10.1246031,9.41351656 C10.1246031,6.38783465 11.9170253,4.71570244 14.6577295,4.71570244 C15.9709306,4.71570244 17.3442461,4.95250975 17.3442461,4.95250975 L17.3442461,7.92282305 L15.8303817,7.92282305 C14.3402244,7.92282305 13.8753969,8.85386763 13.8753969,9.8087633 L13.8753969,12.0729138 L17.2028505,12.0729138 L16.6711353,15.5636921 L13.8753969,15.5636921 L13.8753969,24.0001655 C19.611656,23.0938239 24,18.0987229 24,12.0729138 C24,5.40568058 18.6278134,2.29150032e-13 12,2.29150032e-13 Z",
      },
    ],
  },
  twitter: {
    width: "16",
    height: "16",
    paths: [
      {
        style: "fill:currentColor",
        d:
          "M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z",
      },
    ],
  },
  email: {
    width: "24",
    height: "24",
    paths: [
      {
        style: "fill:currentColor",
        transform:
          "translate(-1260.000000, -303.000000) translate(0.000000, 120.000000) translate(1070.000000, 182.000000) translate(190.000000, 1.000000)",
        d:
          "M7.14854556,8.36070813 L7.14854556,9.64642241 L11.9282948,12.8607081 L16.7063372,9.64642241 L16.7063372,8.36070813 L11.9282948,11.5749938 L7.14854556,8.36070813 Z M17.8649414,15.6740563 C17.8623968,16.3280296 17.3322628,16.8581635 16.6774414,16.8607081 L7.17659318,16.8607081 C6.52177176,16.8581635 5.99163783,16.3280296 5.9899414,15.6740563 L5.9958789,8.54905634 C5.9958789,7.89508313 6.52346818,7.36410098 7.17659318,7.36070813 L16.6774414,7.36070813 C17.3322628,7.36240455 17.8623968,7.8933867 17.8649414,8.54905634 L17.8649414,15.6740563 Z M11.999006,0.00099395345 C5.37132444,0.00099395345 0,5.37331235 0,12.000994 C0,18.6286756 5.37132444,24 11.999006,24 C18.6266877,24 24,18.6286756 24,12.000994 C24,5.37331235 18.6266877,0.00099395345 11.999006,0.00099395345 L11.999006,0.00099395345 Z",
      },
    ],
  },
  website: {
    width: "16",
    height: "16",
    raw:
      '<g fill-rule="evenodd"><path d="M4,13.0909091 L12,13.0909091 L12,2.90909091 L4,2.90909091 L4,13.0909091 Z M11.6363636,0.00727272727 L4.36363636,0 C3.56363636,0 2.90909091,0.654545455 2.90909091,1.45454545 L2.90909091,14.5454545 C2.90909091,15.3454545 3.56363636,16 4.36363636,16 L11.6363636,16 C12.4363636,16 13.0909091,15.3454545 13.0909091,14.5454545 L13.0909091,1.45454545 C13.0909091,0.654545455 12.4363636,0.00727272727 11.6363636,0.00727272727 L11.6363636,0.00727272727 Z" id="Fill-1" fill="#323232"></path></g>',
  },
  "arrow-right": {
    width: 8.9998999,
    height: 16.012106,
    d:
      "m 8.65448,7.2523 c 0.0187,0.016 0.0369,0.033 0.0546,0.051 0.16727,0.1673 0.26226,0.3794 0.28497,0.5977 0.0313,0.2882 -0.0636,0.5875 -0.28451,0.8084 -0.0197,0.02 -0.0401,0.038 -0.061,0.056 l -6.95648,6.9565 c -0.38709,0.3871 -1.01469,0.3871 -1.40177,0 -0.38709,-0.3871 -0.38709,-1.0147 0,-1.4018 L 6.60448,8.0059 0.29075,1.6922 c -0.38709,-0.3871 -0.38709,-1.0147 0,-1.4018 0.38709,-0.3871 1.01468,-0.3871 1.40177,0 z",
  },
  "arrow-left": {
    width: 8.9998999,
    height: 16.01215,
    d:
      "M 8.0078125,0 C 7.7541412,0 7.5021388,0.09746562 7.3085938,0.29101562 L 0.3515625,7.2460938 c -0.0209,0.018 -0.0408469,0.036641 -0.0605469,0.056641 -0.22090999,0.2209 -0.31645625,0.5203937 -0.28515624,0.8085937 0.02270999,0.2183 0.11788625,0.4303563 0.28515624,0.5976563 0.0177,0.018 0.0359875,0.034781 0.0546875,0.050781 L 7.3066406,15.722656 c 0.38709,0.3871 1.0152538,0.3871 1.4023438,0 0.38709,-0.3871 0.38709,-1.015243 0,-1.402344 L 2.3945312,8.0058594 8.7089844,1.6914062 c 0.38709,-0.3870999 0.38709,-1.01329058 0,-1.40039058 C 8.5154444,0.09746562 8.2614838,0 8.0078125,0 Z",
  },
};
