<template>
  <div v-if="total" class="search-summary container hide-print">
    <div class="search-summary__count">
      <span>{{ total }} results</span>
    </div>
    <div class="search-summary__tabs">
      <SearchViewSwitcher
        :initial-value="view"
        v-show="!hideViewSwitcher"
        @change="$emit('changeView', $event)"
      />
    </div>
    <div class="search-summary__print">
      <button
        v-show="showPrint"
        class="btn btn--has-icon search-result__print-btn"
        title="Print selected search results"
        @click="printResults"
      >
        <icon name="print" class="btn__icon btn__icon--left btn__icon--md" />
        <span>{{ printSummary }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { TYPE_LIST } from "@/components/SearchViewSwitcher";
import SearchViewSwitcher from "@/components/SearchViewSwitcher";

export default {
  name: "SearchSummary",
  components: {
    SearchViewSwitcher,
  },

  props: {
    total: {
      type: Number,
      default: 0,
    },
    view: {
      type: String,
    },
    hideViewSwitcher: {
      type: Boolean,
      default: false,
    },
    printList: {
      type: Array,
    },
  },

  computed: {
    printSummary() {
      if (this.printList.length > 0) {
        return `Print ${this.printList.length} result${
          this.printList.length > 1 ? "s" : ""
        }`;
      }
      return "Print results";
    },

    showPrint() {
      return this.view === TYPE_LIST;
    },
  },

  methods: {
    printResults() {
      if (!this.printList.length) {
        this.$emit("toggleShowPrint");
      } else {
        window.print();
      }
    },
  },
};
</script>

<style lang="scss">
.search-summary {
  padding-top: $base-spacing-secondary;
  padding-bottom: $base-spacing-secondary;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;

  &__count {
    margin-bottom: $base-spacing-secondary;
  }

  &__tabs {
    text-align: center;
    width: 100%;
  }

  &__print {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: center;

    &__count {
      width: 30%;
      margin-bottom: 0;
    }

    &__tabs {
      width: 60%;
    }
  }

  @include media-breakpoint-up(md) {
    &__count {
      width: 30%;
    }

    &__tabs {
      width: 40%;
    }

    &__print {
      display: block;
      width: 30%;
      text-align: right;
    }
  }
}

@media print {
  .search-summary {
    display: none;
  }
}
</style>
