<template>
  <component :is="element" class="search-result">
    <button v-if="isPopup" class="search-result__close" @click="$emit('close')">
      <icon name="close" />
    </button>

    <div class="search-result__head">
      <router-link
        v-if="getHref(result).startsWith('/')"
        class="search-result__link"
        :to="getHref(result)"
      >
        <h2 class="search-result__title" v-html="result.title" />
      </router-link>
      <a
        v-else
        :href="getHref(result)"
        target="_blank"
        class="search-result__link"
      >
        <h2 class="search-result__title" v-html="result.title" />
      </a>
      <input
        v-if="showPrint"
        type="checkbox"
        class="search-result__checkbox"
        @change="addToPrint($event, result)"
        :checked="isPrintSelected"
        :id="`item-${result.code}`"
      />
      <label
        v-if="showPrint"
        class="search-result__print"
        :for="`item-${result.code}`"
      >
        Print
      </label>
    </div>
    <div class="search-result__content">
      <div class="search-result__text">
        <div class="typography">
          <p class="search-result__description hide-print" v-html="summary" />
          <span
            v-if="result.type === 'ServiceProvider' && result.physicalAddress"
            class="hide-print"
          >
            {{ formattedAddress }}
          </span>

          <a
            v-if="result.type === 'InformationResource'"
            class="text-wrap"
            :href="result.url"
            target="_blank"
          >
            {{ result.url }}
          </a>
        </div>

        <router-link
          v-if="result.type === 'ServiceProvider'"
          class="search-result__btn btn btn--has-icon hide-print"
          :to="getHref(result)"
        >
          Find out more
          <icon name="caret-right" class="btn__icon btn__icon--right" />
        </router-link>
      </div>

      <Contacts
        v-if="result.type === 'ServiceProvider'"
        class="search-result__contacts no-external-link-icon typography"
        :details="formatContactDetails(result)"
      />
    </div>
    <!-- print content -->
    <div
      v-if="result.type === 'ServiceProvider'"
      class="search-result__print-content"
    >
      <div
        class="search-result__hours"
        v-if="result.openingHours"
        v-html="'<strong>Opening hours:</strong> ' + result.openingHours"
      />
      <div
        class="search-result__address"
        v-if="result.physicalAddress"
        v-html="'<strong>Physical address:</strong> ' + result.physicalAddress"
      />
      <div
        class="search-result__full-content"
        v-if="result.content"
        v-html="'<strong>Content:</strong></br>' + result.content"
      />
    </div>
    <div
      v-if="result.type === 'QuestionAndAnswer'"
      class="search-result__print-content"
    >
      <div
        class="search-result__qa"
        v-if="result.content"
        v-html="result.content"
      />
    </div>
  </component>
</template>

<script>
import Contacts from "./ServiceProvider/Contacts";

export default {
  name: "SearchResult",
  components: {
    Contacts,
  },

  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => {},
    },
    showPrint: {
      type: Boolean,
      default: false,
    },
    isPrintSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    summary() {
      let content = "";

      // For popup view the summary/teaser is limited by 20 characters and no keyword highlight
      // If its not popup, then use the description without any limit
      if (this.isPopup && this.result && this.result.content) {
        content = this.result.content.substr(0, 200) + "...";
      } else if (this.isPopup && this.result && this.result.description) {
        content = this.result.description.substr(0, 200) + "...";
      } else if (!this.isPopup && this.result.description) {
        content = this.result.description;
      }

      return content;
    },

    element() {
      // For popup view we use div as root html else it is list
      return this.isPopup ? "div" : "li";
    },

    formattedAddress() {
      return this.result.physicalAddress
        .trim()
        .replace(/(\r?\n)+/g, "\n")
        .replace(/,? *\n/g, "\n")
        .replaceAll("\n", ", ");
    },
  },

  methods: {
    addToPrint(event, item) {
      // Print feature only available for list view
      if (!this.isPopup) {
        this.$emit("add-to-print", { event, item });
      }
    },

    formatContactDetails(item) {
      const details = [];

      if (item.email) {
        details.push({ Label: "email", Type: "Email", Value: item.email });
      }

      if (item.phoneNumber) {
        details.push({
          Label: "phone",
          Type: "Phone Number",
          Value: item.phoneNumber,
        });
      }

      if (item.website) {
        details.push({
          Label: "website",
          Type: "Website",
          Value: item.website,
        });
      }

      return details;
    },

    getHref(item) {
      switch (item.type) {
        case "QuestionAndAnswer":
          return `/article/${item.code}`;

        case "ServiceProvider":
          return `/community-directory/${item.code}`;

        case "InformationResource":
          if (item.url.length) {
            return `${item.url}`;
          }
          return "";

        case "WebsiteContent":
          return `${item.url}`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss">
.search-result {
  margin-bottom: $base-spacing;
  padding: $base-spacing;
  background-color: white;
  transition: all 0.25s;

  &:hover:not(&--empty) {
    box-shadow: $box-shadow-tile;
  }

  em {
    font-style: normal;
    font-weight: bold;
  }

  &--empty {
    background: unset;
    padding-left: 0;
    padding-right: 0;
  }
}

.search-result__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem(10px);
}

.search-result__link {
  text-decoration: none;
}

.search-result__title {
  padding-right: rem(16px);
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
  }
}

.search-result__print {
  width: rem(60px);
  flex: 0 0 rem(60px);
  cursor: pointer;
}

.search-result__content {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.search-result__btn {
  margin-top: rem(16px);
}

.search-result__text {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.search-result__description {
  margin-top: 0;
}

.search-result__contacts {
  flex: 0;

  padding-top: $base-spacing;
  margin-top: $base-spacing;
  border-top: 1px solid $color-black-40;

  @include media-breakpoint-up(sm) {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
    padding-left: $base-spacing;
    margin-left: $base-spacing;
    border-left: 1px solid $color-black-40;
    flex-basis: rem(240px);
    width: rem(240px);
  }
}

.search-results__print-btn {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.search-result__print-content {
  display: none;
}

@media print {
  .search-result {
    border-bottom: 1px solid $color-black-60;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 2;
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100%;
    display: block !important;

    em {
      font-weight: normal !important;
    }
  }

  .search-result__head,
  .search-result__content {
    display: block;
  }

  .search-result__print {
    display: none;
  }

  .search-result__title {
    padding-right: 0;
  }

  .search-result__description {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .search-result__contacts {
    padding: 0;
    margin: rem(10px) 0 0 0;
    border: none;
    width: auto;
  }

  .search-result__print-content {
    display: block;
    margin-top: rem(10px);
  }

  .search-result__address,
  .search-result__hours {
    margin-bottom: rem(10px);
  }

  .search-result__checkbox {
    display: none;
  }
}
</style>
