const createSvg = () => {
  let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "25pt");
  svg.setAttribute("height", "24pt");
  svg.setAttribute("viewBox", "0 0 25 24");
  svg.setAttribute("class", "alert__icon");
  svg.innerHTML =
    '<g><path style="stroke:none;fill-rule:evenodd;fill:currentColor" d="M 11.5 9 L 13.5 9 L 13.5 7 L 11.5 7 Z M 11.5 17 L 13.5 17 L 13.5 11 L 11.5 11 Z M 12.5 2 C 6.980469 2 2.5 6.480469 2.5 12 C 2.5 17.519531 6.980469 22 12.5 22 C 18.019531 22 22.5 17.519531 22.5 12 C 22.5 6.480469 18.019531 2 12.5 2 Z M 12.5 2 "/></g>';

  return svg;
};

export default {
  inserted: function (el) {
    let alerts = el.querySelectorAll(".alert");
    alerts.forEach((alert) => {
      if (!alert.querySelector("svg")) {
        alert.appendChild(createSvg());
      }
    });
  },
};
