<template>
  <div class="search-locations__result">
    <SearchResult
      :is-popup="true"
      :result="result"
      :show-print="false"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import SearchResult from "../SearchResult";

export default {
  components: {
    SearchResult,
  },

  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
$tile-padding: 8px;

.search-locations__result {
  position: absolute;
  top: rem(24px);
  bottom: rem(24px);
  margin: 0;
  left: 0;

  @include media-breakpoint-up(sm) {
    width: rem(360px);
    left: rem(24px);
  }

  .search-result {
    padding: rem($tile-padding) rem($tile-padding) rem($tile-padding * 2);

    background: #fff;
    width: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    &__head {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      padding-left: rem($tile-padding * 2);
      padding-right: rem($tile-padding * 2);
    }

    &__close {
      @include btn-reset();
      height: 1em;
      width: 1em;
      display: block;
      margin-left: auto;
    }

    &__content {
      flex-direction: column;
      overflow: auto;
      padding-left: rem($tile-padding * 2);
      padding-right: rem($tile-padding * 2);
    }

    &__description {
      overflow: hidden;
      max-height: rem(200px);
      display: block;
    }

    &__contacts {
      padding: rem(24px) 0 0 0;
      margin: rem(16px) 0 0 0;
      border-left: none;
      border-top: 1px solid $color-black-40;
      width: 100%;
      flex-basis: unset;
    }
  }
}

@media print {
  .search-locations__result {
    display: none;
  }
}
</style>
