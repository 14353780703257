export default {
  computed: {
    errorNotFound() {
      return (
        this.$cmsSlug === "page-not-found" ||
        this.$cmsSlug.indexOf("not-found") !== -1 ||
        (this.isError && this.status === 404)
      );
    },

    errorServerError() {
      return this.$cmsSlug === "server-error";
    },
  },
};
