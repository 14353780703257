<template>
  <ul v-if="pages.length > 1" class="pagination">
    <li class="pagination__item pagination__item--prev" :class="{}">
      <button
        class="btn pagination__button pagination__button--prev"
        :class="{ 'btn--disabled': !previousPage }"
        @click="changePage(previousPage)"
      >
        <icon name="arrow-left"></icon>
        <span class="sr-only">Previous</span>
      </button>
    </li>

    <li
      v-for="number in pages"
      class="pagination__item"
      :class="{
        'hide-lg-down': isHidden(number, 9),
        'hide-md-down': isHidden(number, 5),
        'hide-sm-down': isHidden(number, 3),
      }"
      :key="number"
    >
      <button
        class="btn pagination__button pagination__button--page"
        :class="{
          'pagination__button--active': page == number,
        }"
        @click="changePage(number)"
      >
        {{ number }}
      </button>
    </li>

    <li class="pagination__item pagination__item--next">
      <button
        class="btn pagination__button pagination__button--next"
        :class="{ 'btn--disabled': !nextPage }"
        @click.prevent="changePage(nextPage)"
      >
        <icon name="arrow-right"></icon>
        <span class="sr-only">Next</span>
      </button>
    </li>
  </ul>
</template>

<script>
import range from "lodash/range";

export default {
  name: "Pagination",

  props: {
    value: Object,
    itemsCount: Number,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },

  computed: {
    page() {
      return this.value.page;
    },

    previousPage() {
      return this.page - 1;
    },

    nextPage() {
      return this.page < this.pages.length ? this.page + 1 : null;
    },

    pages() {
      const limit = this.itemsPerPage;
      const total = this.itemsCount;

      if (limit > total) {
        return [1];
      }

      const pages = Math.ceil(total / limit);

      return range(1, pages + 1);
    },
  },

  created() {
    // init the model value
    this.changePage(this.page, false);
  },

  methods: {
    offset(page) {
      return (page - 1) * this.itemsPerPage;
    },

    limit(page) {
      return Math.min(this.itemsCount, this.offset(page) + this.itemsPerPage);
    },

    changePage(number, updateURL = true) {
      const updatedParams = {
        page: number,
        offset: this.offset(number),
        limit: this.limit(number),
      };

      this.$emit("input", updatedParams);

      if (updateURL) {
        this.$emit("pageChange");
      }
    },

    isHidden(number, visiblePages) {
      let leftPagesCount,
        rightPagesCount,
        visibleLeft = 0,
        visibleRight = 0;

      switch (this.page) {
        case 1:
          visibleRight = visiblePages - 1;
          break;
        case this.pages.length:
          visibleLeft = visiblePages - 1;
          break;
        default:
          // odd number or visible pages
          if (visiblePages % 2) {
            visibleLeft = (visiblePages - 1) / 2;
            visibleRight = visibleLeft;
          } else {
            // even number or visible pages
            visibleLeft = Math.floor((visiblePages - 1) / 2);
            visibleRight = visibleLeft + 1;
          }

          // adjust for cases near the sides of the pagination
          leftPagesCount = this.page - 1;
          rightPagesCount = this.pages.length - this.page;

          if (leftPagesCount < visibleLeft) {
            visibleRight += visibleLeft - leftPagesCount;
            visibleLeft = leftPagesCount;
          }

          if (rightPagesCount < visibleRight) {
            visibleLeft += visibleRight - rightPagesCount;
            visibleRight = rightPagesCount;
          }
      }

      const isVisible =
        number >= this.page - visibleLeft && number <= this.page + visibleRight;

      return !isVisible;
    },
  },
};
</script>

<style lang="scss">
.pagination {
  margin: 0;
  list-style-type: none;
  display: flex;
}

.pagination__item {
  &:not(:last-of-type) {
    margin-right: rem(32px);

    @include media-breakpoint-up(md) {
      margin-right: $base-spacing;
    }
  }

  &--next {
    flex: 1;
    text-align: right;
  }
}

.pagination__button {
  width: rem(45px);
  height: rem(45px);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--next {
    padding-left: rem(19px);
  }

  &--page {
    background-color: unset;
    border-color: transparent;
    color: $color-body;
  }

  &--active {
    background-color: var(--color-bg-btn);
    color: white;
  }

  &--hide-mobile {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

@media print {
  .pagination {
    display: none;
  }
}
</style>
